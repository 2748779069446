const [visionbaseurl, authbaseurl, baseurl, appurl] = [
  process.env.NEXT_PUBLIC_VISIONBASEURL,
  process.env.NEXT_PUBLIC_AUTHBASEURL,
  process.env.NEXT_PUBLIC_BASEURL,
  process.env.NEXT_PUBLIC_APPURL,
];

export const serverApi = {
  routes: {
    visionOcrKtp: visionbaseurl + '/v1/ocr/id/ktp',
    visionOcrSim: visionbaseurl + '/v1/ocr/id/sim',
    visionOcrPassport: visionbaseurl + '/v1/ocr/id/passport',
    visionFace: visionbaseurl + '/v1/face/verification/',
    visionFaceHandheld: visionbaseurl + '/v1/face/verification/handheld',
    activity: authbaseurl + '/v2/analytics',
    login: authbaseurl + '/v1/auth/login',
    logout: authbaseurl + '/v1/auth/logout',
    otp: authbaseurl + '/v1/auth/otp',
    profile: authbaseurl + '/v1/auth/profile',
    forgotPassword: authbaseurl + '/v1/auth/password/forgot',
    resetPassword: authbaseurl + '/v1/auth/password/reset',
    createUser: authbaseurl + '/v1/admin',
    deleteUser: authbaseurl + '/v1/admin',
    editUser: authbaseurl + '/v1/admin/edit',
    showUser: authbaseurl + '/v1/admin/show',
    userList: authbaseurl + '/v1/admin',
    searchPEP: baseurl + '/v1/watchlist/search',
    batch: baseurl + '/v1/watchlist/batch',
    batchDetail: baseurl + '/v1/watchlist/batch/:batchId',
    singlePEP: baseurl + '/v1/watchlist/history/:id/:profileId',
    watchlistProfilesResolve:
      baseurl + '/v1/watchlist/history/:historyId/update',
    watchlistProfileResolve:
      baseurl + '/v1/watchlist/history/:historyId/:profileId/resolve',
    historyPEP: baseurl + '/v1/watchlist/history',
    watchlistResolve: baseurl + '/v1/watchlist/history/:historyId/resolve',
    watchlistHistoriesResolve: baseurl + '/v1/watchlist/history/update',
    batchScan: baseurl + '/v1/pep/search/batch',
    singleHistory: baseurl + '/v1/watchlist/history',
    watchlistMonitor: baseurl + '/v1/watchlist/monitor/update',
    watchlistMonitorResolve: baseurl + '/v1/watchlist/monitor/:id/resolve',
    watchlistMonitorsResolve: baseurl + '/v1/watchlist/monitor/update/update',
    watchlistMonitorProfilesResolve:
      baseurl + '/v1/watchlist/monitor/:updateId/update',
    watchlistMonitorProfileResolve:
      baseurl + '/v1/watchlist/monitor/:updateId/:profileId/resolve',
    watchlistMonitorProfile: baseurl + '/v1/watchlist/monitor/:id/:profileId',
    watchlistMonitorDetail: baseurl + '/v1/watchlist/monitor',
    watchlistBatchSearch: baseurl + '/v1/watchlist/search/batch',
    accountHistory: baseurl + '/v1/account/history',
    accountHistoryAction: baseurl + '/v1/account/history/:screeningId/action',
    accountHistoryDetail: baseurl + '/v1/account/history',
    bankList: baseurl + '/v1/bank/list',
    emailList: baseurl + '/v1/email/list',
    ipList: baseurl + '/v1/ip/list',
    phoneList: baseurl + '/v1/phone/list',
    identityList: baseurl + '/v1/identity/list',
    adminPermission: authbaseurl + '/v1/admin/permission',
    invitationTokenCheck: authbaseurl + '/v1/admin/invitation/check',
    invite: authbaseurl + '/v1/admin/invitation',
    changePassword: authbaseurl + '/v1/auth/profile/password',
    configList: baseurl + '/v1/config/manage',
    configCreate: baseurl + '/v1/config/manage',
    configDelete: baseurl + '/v1/config/manage',
    configUpdate: baseurl + '/v1/config/manage',
    configShow: baseurl + '/v1/config/manage',
    identity: baseurl + '/v1/identity/history',
    identityDetail: baseurl + '/v1/identity/history',
    identityHistoryDelete: baseurl + '/v1/identity/history/delete',
    identityVerificationHistory: baseurl + '/v1/identity/verification/history',
    identityVerificationDetail: baseurl + '/v1/identity/verification/history',
    identityVerificationDelete:
      baseurl + '/v1/identity/verification/history/delete',
    identityVerificationNewFull: baseurl + '/v1/identity/verification',
    identityVerificationNewBiometric:
      baseurl + '/v1/identity/verification/biometric',
    identityVerificationNewDemography:
      baseurl + '/v1/identity/verification/demography',
    identityVerification3: baseurl + '/v1/identity/verification',
    faceVerification: baseurl + '/v1/face/history',
    faceVerificationDetail: baseurl + '/v1/face/history/',
    faceHistoryDelete: baseurl + '/v1/face/history/delete',
    reports: baseurl + '/v1/report/bank',
    reportDetail: baseurl + '/v1/report/bank/',
    billingBalance: baseurl + '/v1/billing/credit',
    billingChargeHistory: baseurl + '/v1/billing/credit/charge/history',
    billingCreditHistory: baseurl + '/v1/billing/credit/topup/history',
    billingCreateTopup: baseurl + '/v1/billing/credit/topup',
    billingMonthlyHistory: baseurl + '/v1/billing/history',
    billingMonthlyHistoryDetail: baseurl + '/v1/billing/history/detail',
    accountScreeningScan: baseurl + '/v1/account/screening',
    bankScan: baseurl + '/v1/bank/scan',
    bankHistory: baseurl + '/v1/bank/history',
    bankHistoryDelete: baseurl + '/v1/bank/history/delete',
    accountHistoryDelete: baseurl + '/v1/account/history/delete',
    pepHistoryDelete: baseurl + '/v1/pep/history/delete',
    developerList: authbaseurl + '/v1/dev/token',
    developerTypes: authbaseurl + '/v1/dev/token/list',
    developerGenerate: authbaseurl + '/v1/dev/token/generate',
    developerDeleteToken: authbaseurl + '/v1/dev/token',
    analytics: baseurl + '/v1/analytics/stats/account',
    createComment: baseurl + '/v1/account/history/:screeningId/comment',
    comments: baseurl + '/v1/account/history/:screeningId/comments',
    deleteComment:
      baseurl + '/v1/account/history/:screeningId/comment/:commentId',
  },
};

export const key = '';

export const general = {
  appName: 'Synapses',
  // baseUrl: 'https://localhost:3000',
  baseUrl: appurl,
};

export const searchTypes = [
  { value: 'match_exact', label: 'Match Exact' },
  { value: 'match_phrase', label: 'Match Phrase' },
  { value: 'match', label: 'Match' },
];

export const defaultConfig = {
  _id: 'b422f74a-037d-4738-a630-8746a4610dd1',
  name: '',
  created_at: '2020-06-19 13:37:17.266975',
  updated_at: '2020-06-19 13:37:17.267017',
  information: {
    user_id: 8,
    type_id: 2,
    permission_id: 2,
    company_id: null,
  },
  config: {
    general: {
      filter: {
        status: {
          if: [
            { '<=': [{ var: 'score' }, -20] },
            'reject',
            {
              and: [
                { '<=': [{ var: 'score' }, -10] },
                { '>': [{ var: 'score' }, -20] },
              ],
            },
            'review',
            'pass',
          ],
        },
      },
    },
    modules: {
      bank: {
        fields: [
          'fullName',
          'bankName',
          'bankCode',
          'bankRegionName',
          'bankBranchName',
          'bankBranchAddress',
          'report',
          'review',
          'accountVerified',
          'bankHighRisk',
          'whitelist',
          'blacklist',
        ],
        scores: {
          report: {
            notVerified: -10,
            verified: -20,
          },
          review: {
            oneStar: -5,
            twoStar: -2.5,
            threeStar: 0,
            fourStar: 1,
            fiveStar: 2.5,
          },
          accountVerified: 20,
          bankHighRisk: -3,
          whitelist: 50,
          blacklist: -50,
        },
        settings: {},
        isActive: true,
      },
      phone: {
        fields: [
          'fullName',
          'providerName',
          'providerType',
          'countryName',
          'countryCode',
          'nationalNumber',
          'regionName',
          'validNumber',
          'trustedProvider',
          'review',
          'report',
          'whitelist',
          'blacklist',
        ],
        scores: {
          report: {
            notVerified: -10,
            verified: -20,
          },
          review: {
            oneStar: -5,
            twoStar: -2.5,
            threeStar: 0,
            fourStar: 1,
            fiveStar: 2.5,
          },
          trustedProvider: 0,
          whitelist: 50,
          blacklist: -50,
        },
        settings: {},
        isActive: true,
      },
      email: {
        fields: [
          'fullName',
          'images',
          'validity',
          'disposable',
          'connected',
          'full',
          'deliverable',
          'records',
          'breaches',
          'privateHost',
          'publicHost',
          'highRiskHost',
          'whitelist',
          'blacklist',
        ],
        scores: {
          invalid: -25,
          privateHost: 5,
          publicHost: 0,
          highRiskHost: -5,
          blacklist: -50,
          whitelist: 50,
        },
        settings: {},
        isActive: true,
      },
      ip: {
        fields: [
          'as',
          'asname',
          'city',
          'continent',
          'continentCode',
          'country',
          'countryCode',
          'currency',
          'district',
          'isp',
          'lat',
          'lon',
          'org',
          'region',
          'regionName',
          'zip',
          'proxy',
          'mobile',
          'hosting',
          'timezone',
          'whitelist',
          'blacklist',
        ],
        scores: {
          proxy: -5,
          mobile: 0,
          hosting: 0,
          whitelist: 25,
          blacklist: -25,
        },
        settings: {},
        isActive: true,
      },
      aml: {
        fields: [
          'nativeName',
          'gender',
          'birthPlace',
          'birthDate',
          'deathDate',
          'description',
          'detailDescription',
          'updatedAt',
          'alias',
          'lowAliases',
          'previousAliases',
          'maidenNames',
          'spellingVariationNames',
          'languageVariationNames',
          'birthPlaces',
          'birthDates',
          'images',
        ],
        scores: {
          match: -10,
          filterMatch: -15,
          sanctionMatch: -50,
        },
        settings: {
          searchType: 'match',
          searchSimilarityAlgorithm: 'levenshtein',
          searchAliasFields: [
            'nativeName',
            'alias',
            'lowAliases',
            'previousAliases',
            'spellingVariationNames',
            'languageVariationNames',
            'maidenNames',
          ],
          searchDataSources: ['kredibel'],
          profileTypes: ['PEP', 'SIP', 'RCA', 'Sanction', 'Watchlist'],
          minSimilarity: 0.8,
          pseudoMinSimilarity: 1,
          ongoingScreening: false,
          advancedSearch: false,
          dataset: [
            'wp',
            'executive',
            'sp',
            'sw',
            'menteri',
            'wmenteri',
            'dpr',
            'dpd',
            'ma',
            'mk',
            'ky',
            'kejagung',
            'kpu',
            'amb',
            'bpk',
            'kd',
            'parpol',
            'polri',
            'tni',
            'bank',
            'bumn',
            'afdb',
            'iadb',
            'eeas',
            'unsc',
            'mofa',
            'dgt',
            'nbctf',
            'gon',
            'pma-lfl',
            'moi',
            'rosfin',
            'seco',
            'sdfm',
            'gbhmt',
            'repet',
            'csemas',
            'ofac',
            'dfat',
            'dttot',
            'fiu',
            'mhaml',
            'dte',
            'sprm',
            'kpk',
            'interpol',
            'crimes-id',
          ],
        },
        isActive: true,
      },
      kycFace: {
        fields: ['matched'],
        scores: {},
        settings: {},
        isActive: true,
      },
      kycIdentity: {
        fields: [
          'id',
          'province',
          'name',
          'gender',
          'bloodType',
          'address',
          'rtrw',
          'village',
          'district',
          'religion',
          'maritalStatus',
          'occupation',
          'nationality',
          'city',
          'pob',
          'dob',
        ],
        scores: {},
        settings: {},
        isActive: true,
      },
      transaction: {
        fields: ['amount', 'totalTransaction'],
        scores: {},
        settings: {},
        isActive: true,
      },
    },
    predefinedRules: [],
    rules: [],
    general: {
      filter: {
        status: {
          if: [
            { '<=': [{ var: 'score' }, -20] },
            'reject',
            {
              and: [
                { '<=': [{ var: 'score' }, -10] },
                { '>': [{ var: 'score' }, -20] },
              ],
            },
            'review',
            'pass',
          ],
        },
      },
    },
    rules: [],
  },
};

export const amlSettingsDataset = {
  politicians: [
    // label, description, value, is checked by default?
    [
      'World Politician',
      'Politikus dari seluruh penjuru dunia [global].',
      'wp',
      true,
    ],
    ['Executive', 'Eksekutif [id].', 'executive', true],
    ['Stafsus Presiden', 'Daftar Staf Khusus Presiden [id].', 'sp', true],
    [
      'Stafsus Wakil Presiden',
      'Daftar Staf Khusus Wakil Presiden [id].',
      'sw',
      true,
    ],
    ['Menteri', 'Daftar Menteri [id].', 'menteri', true],
    ['WMENTERI', 'SW [id].', 'wmenteri', true],
    [
      'DPR',
      'Anggota DPR dari tingkat Pusat hingga tingkat Kabupaten atau Kota [id].',
      'dpr',
      true,
    ],
    ['DPD', 'Anggota DPD RI [id].', 'dpd', true],
    ['MA', 'Anggota Mahkamah Agung [id].', 'ma', true],
    ['MK', 'Anggota Mahkamah Konstitusi [id].', 'mk', true],
    ['KY', 'Anggota Komisi Yudisial [id].', 'ky', true],
    ['KEJAGUNG', 'Kejaksaan Agung [id].', 'kejagung', true],
    ['KPU', 'Komisi Pemilihan Umum [id].', 'kpu', true],
    ['AMB', 'AMB [id].', 'amb', true],
    ['BPK', 'Badan Pemeriksa Keuangan [id].', 'bpk', true],
    [
      'Kepala Daerah',
      'Daftar Kepala Daerah dari tingkat Provinsi hingga Kabupaten atau Kota [id].',
      'kd',
      true,
    ],
    ['PARPOL', 'Partai Politik [id].', 'parpol', true],
    ['POLRI', 'Daftar Perwira Tinggi Polri [id].', 'polri', true],
    ['TNI', 'Daftar Perwira Tinggi TNI [id].', 'tni', true],
    ['BANK', 'Daftar Bank [id].', 'bank', true],
    ['BUMN', 'Daftar Direksi BUMN [id].', 'bumn', true],
  ],
  sanctions: [
    ['AFDB', 'African Development Bank [af]', 'afdb', true],
    ['IADB', 'Inter-American Development Bank [global]', 'iadb', true],
    ['EEAS', 'European Union External Action Service [eu]', 'eeas', true],
    ['UNSC', 'UN Consolidated Sanctions [global]', 'unsc', true],
    ['MOFA', 'Ministry of Foreign Affairs [id]', 'mofa', true],
    ['DGT', 'Directorate General of Taxes [id]', 'dgt', true],
    [
      'NBCTF',
      'National Bureau for Counter Terror Financing [global]',
      'nbctf',
      true,
    ],
    ['GON', 'GON [global]', 'gon', true],
    ['PMA-LFL', 'PMA-LFL [global]', 'pma-lfl', true],
    ['MOI', 'MOI [global]', 'moi', true],
    ['ROSFIN', 'ROSFIN [global]', 'rosfin', true],
    ['SECO', 'Swiss SECO Sanctions [ch]', 'seco', true],
    ['SDFM', 'SDFM [global]', 'sdfm', true],
    ['GBHMT', 'GB Consolidated List of Targets [gb]', 'gbhmt', true],
    ['REPET', 'REPET [global]', 'repet', true],
    [
      'CSEMAS',
      'Canada Special Economic Measures Act Sanctions [ca]',
      'csemas',
      true,
    ],
    ['OFAC', 'OFAC Consolidated List [global]', 'ofac', true],
    ['DFAT', 'Australian DFAT Sanctions List [au]', 'dfat', true],
    [
      'DTTOT',
      'Daftar Terduga Teroris dan Organisasi Teroris [id]',
      'dttot',
      true,
    ],
    ['FIU', 'FIU [id]', 'fiu', true],
    [
      'MHAML',
      'Ministry of Home Affairs Malaysia Sanction List [my]',
      'mhaml',
      true,
    ],
    ['DTE', 'DTE [global]', 'dte', true],
    ['SPRM', 'Malaysian Anti-Corruption Commission [my]', 'sprm', true],
    ['KPK', 'Tindak Pidana Korupsi [id]', 'kpk', true],
    [
      'INTERPOL',
      'International Criminal Police Organization [global]',
      'interpol',
      true,
    ],
    ['crimes-id', 'CRIMES-ID [id]', 'crimes-id', true],
  ],
};

export const searchAliases = [
  'nativeName',
  'alias',
  'lowAliases',
  'previousAliases',
  'spellingVariationNames',
  'languageVariationNames',
  'maidenNames',
];
export const searchSources = ['kredibel', 'dowjones'];
